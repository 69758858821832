import React, { useEffect, useRef, useState } from 'react';
import ArrowLeftIcon from '../../assets/icons/leftArrowIcon';
import ArrowRightIcon from '../../assets/icons/rightArrowIcon';
import './carousel.scss';

const Carousel = ({ children, isSmall = false }) => {
  const carouselRef = useRef(null);
  const prevChildrenLengthRef = useRef(children.length);
  const [isPrevDisabled, setIsPrevDisabled] = useState(true);
  const [isNextDisabled, setIsNextDisabled] = useState(false);

  const checkButtons = () => {
    if (!carouselRef.current) return;

    const { scrollLeft, scrollWidth, clientWidth } = carouselRef.current;
    setIsPrevDisabled(scrollLeft <= 0);
    setIsNextDisabled(scrollLeft + clientWidth >= scrollWidth);
  };

  const scroll = (direction) => {
    if (!carouselRef.current) return;

    const { scrollLeft, clientWidth } = carouselRef.current;
    const scrollAmount = direction === 'next' ? clientWidth : -clientWidth;

    carouselRef.current.scrollTo({
      left: scrollLeft + scrollAmount,
      behavior: 'smooth',
    });
  };

  const scrollToEnd = () => {
    if (!carouselRef.current) return;

    const { scrollWidth, clientWidth } = carouselRef.current;
    carouselRef.current.scrollTo({
      left: scrollWidth - clientWidth,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    checkButtons();
  }, []);

  useEffect(() => {
    if (
      children.length > prevChildrenLengthRef.current &&
      carouselRef.current.scrollWidth > carouselRef.current.clientWidth
    ) {
      console.log(
        'New items added, content exceeds screen size. Auto-scrolling to the end.',
      );
      scrollToEnd();
    }

    prevChildrenLengthRef.current = children.length;
  }, [children]);

  return (
    <div
      className="carousel-wrapper"
      style={{
        overflow: 'hidden',
        width: '100%',
      }}
    >
      <div
        className="carousel-container"
        ref={carouselRef}
        style={{
          display: 'flex',
          gap: 15,
          overflowX: 'scroll',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
        }}
        onScroll={checkButtons}
      >
        {children}
      </div>
      {!isPrevDisabled && (
        <button
          onClick={() => scroll('prev')}
          disabled={isPrevDisabled}
          className={`previous_btn  ${isSmall && 'small'} `}
          style={{
            position: 'absolute',
            left: '15px',
            zIndex: 999999,
            backgroundColor: 'darkgray',
          }}
        >
          <ArrowLeftIcon />
        </button>
      )}
      {!isNextDisabled && (
        <button
          onClick={() => scroll('next')}
          disabled={isNextDisabled}
          className={`next_btn  ${isSmall && 'small'} `}
          style={{
            position: 'absolute',
            right: '15px',
            zIndex: 999999,
            backgroundColor: 'darkgray',
          }}
        >
          <ArrowRightIcon />
        </button>
      )}
    </div>
  );
};

export default Carousel;
