import React from 'react';

function ArrowRightIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7"
      height="10"
      fill="none"
      viewBox="0 0 7 10"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M6.317 4.489a.773.773 0 01.075.114.811.811 0 01.09.136c.017.05.029.1.035.152a.462.462 0 010 .305.756.756 0 01-.034.152.811.811 0 01-.091.136.772.772 0 01-.075.114L1.753 9.676A.886.886 0 01.552 9.63a.789.789 0 01.032-1.146L4.48 5 .584 1.515A.789.789 0 01.552.369.886.886 0 011.753.324L6.317 4.49z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default ArrowRightIcon;
