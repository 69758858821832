import React from 'react';

function ArrowLeftIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7"
      height="10"
      fill="none"
      viewBox="0 0 7 10"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M.546 4.489a.773.773 0 00-.074.114.812.812 0 00-.091.136.756.756 0 00-.035.152.462.462 0 000 .305.756.756 0 00.035.152.812.812 0 00.09.136c.022.04.047.078.075.114L5.11 9.676a.886.886 0 001.202-.045.789.789 0 00-.032-1.146L2.383 5 6.28 1.515A.789.789 0 006.312.369.886.886 0 005.11.324L.546 4.49z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default ArrowLeftIcon;
