import axios from 'axios';
import { Comment } from './Comment';
import { Poll } from './Poll';

// Get the REACT_APP_AD_API_URL from .env
const AD_API_URL = process.env.REACT_APP_AD_API_URL;

export const API = {
  status: 'Yahoo',
  /* Post List at least 10 */
  getPostList: async (payload) => {
    let page;
    if (payload && payload.page) {
      page = 'page=' + payload.page + '&';
    } else {
      page = '';
    }
    return await axios
      .get(
        process.env['REACT_APP_API_URL'] +
          '/thirdparty/news-feeds?' +
          page +
          window.location.href.split('?')[1],
      )
      .catch((error) => {
        return error;
      })
      .then((response) => {
        return response;
      });
  },
  userInfo: async () => {
    try {
      return await axios
        .get(
          process.env['REACT_APP_API_URL'] +
            '/thirdparty/user-info?' +
            window.location.href.split('?')[1],
        )
        .catch((error) => {
          return error;
        })
        .then((response) => {
          return response.data;
        });
    } catch (error) {
      console.log(error);
    }
  },
  getTotalNoSchedulePosts: async () => {
    return await axios
      .get(
        process.env['REACT_APP_API_URL'] +
          '/thirdparty/scheduled-posts/total-count?' +
          window.location.href.split('?')[1],
      )
      .catch((error) => {
        return error;
      })
      .then((response) => {
        return response;
      });
  },
  getSchedulePosts: async (payload) => {
    return await axios
      .get(
        process.env['REACT_APP_API_URL'] +
          '/thirdparty/scheduled-posts?page=' +
          payload.page +
          '&' +
          window.location.href.split('?')[1],
      )
      .catch((error) => {
        return error;
      })
      .then((response) => {
        return response;
      });
  },

  removePost: async (payload) => {
    return await axios
      .post(
        process.env['REACT_APP_API_URL'] +
          '/thirdparty/news-feed-client/' +
          payload.id +
          '/delete?' +
          window.location.href.split('?')[1],
      )
      .catch((error) => {
        return error;
      })
      .then((response) => {
        return response;
      });
  },

  updatePost: async (payload) => {
    let bodyFormData = new FormData();
    bodyFormData.set('title', payload.title);
    bodyFormData.set('description', payload.description);
    bodyFormData.set('newsFeedClientId', payload.newsFeedClientId);

    if (payload.publishLaterDate) {
      bodyFormData.set('publishLaterDate', payload.publishLaterDate);
    }

    try {
      console.log('payload', payload);

      if (payload.files && payload.files.length) {
        console.log('videothumbnail', payload.videoThumbnail);

        payload.files.forEach((file, index) => {
          const videoThumbnail = payload.videoThumbnail.filter(
            (t) => t.index === index,
          );

          console.log('data', videoThumbnail);

          bodyFormData.append(
            'videoThumbnail',
            videoThumbnail[0]?.file ||
              JSON.stringify({
                url: videoThumbnail[0]?.url,
                index,
                id: videoThumbnail[0].id,
              }) ||
              JSON.stringify({ index, id: videoThumbnail[0].id }),
          );
        });
      }

      // Handle publish settings
      if (payload.publishNow) {
        bodyFormData.set('publishNow', '1');
      }
      if (payload.isSharedWithAll) {
        bodyFormData.set('isSharedWithAll', '1');
      }
      if (payload.isSharedWithAdmin) {
        bodyFormData.set('isSharedWithAdmin', '1');
      }
      if (payload.isSharedWithLearner) {
        bodyFormData.set('isSharedWithLearner', '1');
      }
      if (payload.onlySharedWith) {
        bodyFormData.set('onlySharedWith', payload.onlySharedWith);
      }

      // Append deleted media
      if (payload.deletedMedia.length > 0) {
        payload.deletedMedia.forEach((media) =>
          bodyFormData.append('deletedMedia', media),
        );
      }

      // Append files
      if (payload.files.length > 0) {
        payload.files.forEach((file) =>
          bodyFormData.append('files', file.file),
        );
      }

      // Perform the POST request
      const response = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/thirdparty/news-feed/${
          payload.newsFeedID
        }/update?${window.location.href.split('?')[1]}`,
        headers: { 'Content-Type': 'multipart/form-data' },
        data: bodyFormData,
      });

      return response;
    } catch (error) {
      console.error('Error during updatePost:', error);
      return { error: error.message };
    }
  },
  postComment: async (payload) => {
    let bodyFormData = new FormData();
    bodyFormData.set('newsFeedClientId', payload.newsFeedClientID);
    bodyFormData.set('commentDescription', payload.message);
    return await axios({
      method: 'post',
      url:
        process.env.REACT_APP_API_URL +
        '/thirdparty/news-feed/comment?' +
        window.location.href.split('?')[1],
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: bodyFormData,
    })
      .catch((error) => {
        return error;
      })
      .then((response) => {
        return response;
      });
  },
  createPost: async (payload) => {
    let bodyFormData = new FormData();
    bodyFormData.set('title', payload.title);
    bodyFormData.set('description', payload.description);

    bodyFormData.set('poll', payload.poll);
    if (payload.files && payload.files.length) {
      console.log('video thum', payload.videoThumbnail);
      if (payload.videoThumbnail) {
        payload.files.forEach((file, index) => {
          const videoThumbnail = payload.videoThumbnail.filter(
            (t) => t.index === index,
          );

          bodyFormData.append(
            'videoThumbnail',
            videoThumbnail[0]?.file || JSON.stringify({ index }),
          );
        });
      }
    }

    if (payload.poll === '1') {
      bodyFormData.set('startsAt', payload.startsAt);
      if (payload.closesAt) {
        bodyFormData.set('closesAt', payload.closesAt);
      }

      bodyFormData.set('pollQuestion', payload.pollQuestion);
      bodyFormData.set('type', payload.type);
    }

    if (payload.options.length > 0) {
      let j = 0;
      while (j < payload.options.length) {
        bodyFormData.append(
          'options',
          payload.options[j].name +
            ',' +
            payload.options[j].order +
            ',' +
            payload.options[j].url,
        );
        j++;
      }
    }

    if (payload.isSharedWithAdmin) {
      bodyFormData.set('isSharedWithAdmin', 1);
    } else if (payload.isSharedWithLearner) {
      bodyFormData.set('isSharedWithLearner', 1);
    } else if (payload.onlySharedWith) {
      bodyFormData.set('onlySharedWith', 1);
    }

    if (payload.publishLaterDate) {
      bodyFormData.set('publishLaterDate', payload.publishLaterDate);
    } else {
      bodyFormData.set('publishNow', payload.publishNow);
    }

    // Form Data for images
    if (payload.files.length > 0) {
      let i = 0;
      while (i < payload.files.length) {
        bodyFormData.append('files', payload.files[i].file);
        i++;
      }
    }

    return await axios({
      method: 'post',
      url:
        process.env.REACT_APP_API_URL +
        '/thirdparty/news-feed/create?' +
        window.location.href.split('?')[1],
      headers: { 'Content-Type': 'multipart/form-data' },
      data: bodyFormData,
    })
      .catch(function (error) {
        console.log(error);
        return error.response;
      })
      .then((response) => {
        return response;
      });
  },
  likePost: async (payload) => {
    return await axios({
      method: 'post',
      url:
        process.env.REACT_APP_API_URL +
        '/thirdparty/news-feed/like?' +
        window.location.href.split('?')[1],
      data: {
        newsFeedClientId: payload.newsFeedClientId,
      },
    })
      .catch(function (error) {
        return error;
      })
      .then((response) => {
        return response;
      });
  },
  getComments: async (payload) => {
    let currentPage = 1;
    if (payload.currentPage) {
      currentPage = payload.currentPage;
    }
    return await axios
      .get(
        process.env.REACT_APP_API_URL +
          '/thirdparty/news-feed-client/' +
          payload.id +
          '/comments?page=' +
          currentPage +
          '&' +
          window.location.href.split('?')[1],
      )
      .catch(function (error) {
        return error;
      })
      .then((response) => {
        return response;
      });
  },
  fileUpload: async (payload) => {
    let bodyFormData = new FormData();
    bodyFormData.append('file', payload.file);
    return await axios({
      method: 'post',
      url:
        process.env.REACT_APP_API_URL +
        '/thirdparty/upload/thumbnail?' +
        window.location.href.split('?')[1],
      headers: { 'Content-Type': 'multipart/form-data' },

      data: bodyFormData,
    })
      .catch(function (error) {
        return error;
      })
      .then((response) => {
        return response;
      });
  },
  uploader: async (formData) => {
    try {
      // this.setState({ errorMessage: "" });
      const UPLOADER = `https://awsuploader.services.olive.media`;
      axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
      axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
      axios.defaults.headers.post['Access-Control-Allow-Headers'] =
        'Origin, X-Requested-With, Content-Type, Accept';

      let data = await axios.post(`${UPLOADER}/upload`, formData);
      return data.data.data[0].fileToSend;
    } catch (error) {
      console.log('error in uploader', JSON.parse(JSON.stringify(error)));
      // return error;

      if (error.response) {
        console.log('error in uploader res', error);
      } else {
        console.log('504 error', error);
      }

      return error;
    }
  },

  setPostCommentStatus: async (payload) => {
    try {
      let turn = payload.status ? 'on' : 'off';
      return await axios({
        method: 'get',
        url:
          process.env.REACT_APP_API_URL +
          '/thirdparty/news-feed-client/' +
          payload.id +
          '/comment/' +
          turn +
          '?' +
          window.location.href.split('?')[1],
      })
        .catch((error) => error)
        .then((response) => response);
    } catch (error) {
      console.log(error);
    }
  },
  votePoll: async (payload) => {
    try {
      return await axios({
        method: 'post',
        url:
          process.env.REACT_APP_API_URL +
          '/thirdparty/poll/' +
          payload.id +
          '/vote?' +
          window.location.href.split('?')[1],
        data: {
          options: payload.options,
        },
      })
        .catch((error) => error)
        .then((response) => response);
    } catch (error) {
      console.log(error);
    }
  },
  deletePoll: async (payload) => {
    try {
      return await axios({
        method: 'post',
        url:
          process.env.REACT_APP_API_URL +
          '/thirdparty/poll/' +
          payload.id +
          '/delete?' +
          window.location.href.split('?')[1],
        data: {
          options: payload.options,
        },
      })
        .catch((error) => error)
        .then((response) => response);
    } catch (error) {
      console.log(error);
    }
  },
  editPoll: async (payload) => {
    try {
      return await axios({
        method: 'post',
        url:
          process.env.REACT_APP_API_URL +
          '/thirdparty/poll/' +
          payload.id +
          '/update?' +
          window.location.href.split('?')[1],
        data: {
          pollQuestion: payload.pollQuestion,
          startsAt: payload.startsAt,
          type: payload.type,
          closesAt: payload.closesAt,
          options: payload.options,
        },
        headers: { 'Content-Type': 'application/json' },
      })
        .catch((error) => error)
        .then((response) => response);
    } catch (error) {
      console.log(error);
    }
  },
  showReplyComemnts: async (payload) => {
    try {
      return await axios({
        method: 'get',
        url:
          process.env.REACT_APP_API_URL +
          '/thirdparty/news-feed/comment/' +
          payload.commentID +
          '/replies?' +
          window.location.href.split('?')[1],
      })
        .catch((error) => error)
        .then((response) => response);
    } catch (error) {
      console.log(error);
    }
  },

  replyComment: async (payload) => {
    try {
      return await axios({
        method: 'post',
        url:
          process.env.REACT_APP_API_URL +
          '/thirdparty/news-feed/comment/' +
          payload.commentID +
          '/reply?' +
          window.location.href.split('?')[1],

        data: {
          replyDescription: payload.replyDescription,
          newsFeedClientId: payload.newsFeedClientID,
        },
        headers: { 'Content-Type': 'application/json' },
      })
        .catch((error) => error)
        .then((response) => response);
    } catch (error) {
      console.log(error);
    }
  },

  likeOnComment: async (payload) => {
    try {
      return await axios({
        method: 'post',
        url:
          process.env.REACT_APP_API_URL +
          '/thirdparty/news-feed/comment/' +
          payload.commentID +
          '/like?' +
          window.location.href.split('?')[1],

        data: {
          newsFeedClientId: payload.newsFeedClientID,
        },
        headers: { 'Content-Type': 'application/json' },
      })
        .catch((error) => error)
        .then((response) => response);
    } catch (error) {
      console.log(error);
    }
  },
  pinTogglePost: async (payload) => {
    try {
      let pinText = payload?.status === true ? 'unpin' : 'pin';
      return await new Promise((resolve, reject) => {
        return axios({
          method: 'get',
          url:
            process.env.REACT_APP_API_URL +
            '/thirdparty/news-feed-client/' +
            payload?.id +
            '/' +
            pinText +
            '?' +
            window.location.href.split('?')[1] +
            '&pinnedBy=admin',
          headers: { 'Content-Type': 'application/json' },
        })
          .then((result) => resolve(result))
          .catch((error) => reject(error));
      });
    } catch (error) {
      throw new Error(error);
    }
  },
  // =============================================================================
  // ADS TECHNOLOGY API

  getAdsPageData(platformId, section) {
    return new Promise(function (resolve, reject) {
      axios({
        method: 'get',
        url: `${AD_API_URL}/third-party/advertisement?platformId=${platformId}&section=${section}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          thirdpartysecret: 'rIBYcuXDNgQ1lVVUa6jw/Q==',
        },
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log('error', error);
          reject(error);
        });
    });
  },

  postAdsPageData(platformId, data) {
    return new Promise(function (resolve, reject) {
      axios({
        method: 'post',
        url: `${AD_API_URL}/third-party/track-advertisement`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          thirdpartysecret: 'rIBYcuXDNgQ1lVVUa6jw/Q==',
        },
        data: data,
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log('error', error);
          reject(error);
        });
    });
  },

  // getAdsPageData: async (platformId) => {
  //   console.log('getAdsPageData', platformId);
  //   let page;
  //   if (platformId && platformId.page) {
  //     page = 'page=' + platformId.page + '&';
  //   } else {
  //     page = '';
  //   }
  //   return await axios
  //     .get(
  //       process.env['REACT_APP_AD_API_URL'] +
  //       `/third-party/advertisement?platformId` +
  //       page +
  //       window.location.href.split('?')[1],
  //     )
  //     .catch((error) => {
  //       return error;
  //     })
  //     .then((response) => {
  //       return response;
  //     });
  // },

  // =============================================================================
  comment: Comment,
  poll: Poll,
};

// hirdparty/news-feed/comment/:id/replies
